import React, { ComponentProps, FC, PropsWithChildren, useEffect } from 'react'
import Header from './Layout/Header'
import styles from 'styles/components/Layout.module.sass'
import LoginCta from './Welcome/LoginCta'

type LayoutProps = PropsWithChildren<{
    headerProps: ComponentProps<typeof Header>
    loginCta?: boolean
    userId?: number
}>

const Layout: FC<LayoutProps> = ({
    headerProps,
    children,
    loginCta,
    userId
}) => {
    // NextJS doesn't restore scroll position on route changes automatically
    useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), [])

    return (
        <div className={styles.div}>
            {!userId && loginCta && (
                <LoginCta
                    text={
                        'Социальная сеть для креативных людей. Давайте объединяться!'
                    }
                    buttonText={'Войти'}
                    href="/"
                />
            )}
            <Header {...headerProps} />
            {children}
        </div>
    )
}

export default Layout
