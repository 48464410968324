import { FC } from 'react'
import styles from 'styles/components/Headline.module.sass'
import classNames from 'classnames'

type WordStyle = {
    textColor?: string
    bgColor?: string
}

type HeadlineProps = { children: string; wordStyles?: WordStyle[] }

const Headline: FC<HeadlineProps> = ({ children, wordStyles }) => {
    if (wordStyles && wordStyles.length > children.length)
        throw new Error(
            'wordStyles length must be equal or less than children length'
        )

    return (
        <div className={styles.slogan}>
            {children.split(' ').map((word, i, array) => {
                const wordStyle = wordStyles && wordStyles[i]

                return (
                    <div
                        key={i}
                        className={classNames(styles.word, {
                            [styles.lastWord ?? '']: i === array.length - 1
                        })}
                        style={{
                            color: wordStyle?.textColor,
                            backgroundColor: wordStyle?.bgColor
                        }}
                    >
                        {word}
                    </div>
                )
            })}
        </div>
    )
}

export default Headline
