import { GraphQLError } from 'graphql/error'
import { FormikErrors } from 'formik'

const extractValidationErrors = <T>(
    errors: readonly GraphQLError[]
): FormikErrors<T> =>
    Object.fromEntries(
        errors
            .filter(error => error?.extensions.code === 'BAD_USER_INPUT')
            .map(validationError => [
                validationError.extensions.item,
                validationError.message
            ])
    )

export default extractValidationErrors
