import { useRouter } from 'next/router'
import * as Yup from 'yup'
import MultiFieldTextForm from '../DataEntry/MultiFieldTextForm'
import styles from '../../styles/pages/Auth.module.sass'
import { FC } from 'react'
import { LoginDocument } from '../../graphql/generated'
import extractValidationErrors from '../../util/extractValidationErrors'
import isBrowser from '../../util/isBrowser'
import { useMutation } from '@apollo/client'

type LoginFormProps = {
    heading: string
    emailPlaceholder: string
    password: string
    submit: string
}

const LoginForm: FC<LoginFormProps> = ({
    heading,
    emailPlaceholder,
    password,
    submit
}) => {
    const router = useRouter()

    const loginSchema = Yup.object().shape({
        email: Yup.string().required('Обязательное поле'),
        password: Yup.string().required('Обязательное поле')
    })

    const [login] = useMutation(LoginDocument)

    const autoCompletedValue = (fieldName: string): string | undefined =>
        isBrowser
            ? document.querySelector<HTMLInputElement>(
                  `input[name="${fieldName}"]`
              )?.value || undefined
            : undefined

    return (
        <MultiFieldTextForm
            headerClassName={styles.loginHeader}
            containerClassName={styles.form}
            title={heading}
            submitText={submit}
            fields={
                [
                    {
                        name: 'email',
                        placeholder: emailPlaceholder,
                        autoFill: 'email',
                        initialValue: autoCompletedValue('email')
                    },
                    {
                        name: 'password',
                        placeholder: password,
                        autoFill: 'current-password',
                        password: true,
                        initialValue: autoCompletedValue('password')
                    }
                ] as const
            }
            validationSchema={loginSchema}
            onSubmit={async ({ email, password }, helpers) => {
                const response = await login({ variables: { email, password } })

                helpers.setSubmitting(false)

                if (response.errors) {
                    helpers.setErrors(extractValidationErrors(response.errors))
                    return
                }

                await router.reload()
            }}
        />
    )
}

export default LoginForm
