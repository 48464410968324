import { FC } from 'react'
import { useRouter } from 'next/router'
import * as Yup from 'yup'
import styles from '../../styles/pages/Auth.module.sass'
import { RegisterDocument } from '../../graphql/generated'
import extractValidationErrors from '../../util/extractValidationErrors'
import NewPasswordForm from './NewPasswordForm'
import { useMutation } from '@apollo/client'

type RegisterFormProps = {
    heading: string
    emailPlaceholder: string
    passwordPlaceholder: string
    repeatPasswordPlaceholder: string
    submit: string
}

const RegisterForm: FC<RegisterFormProps> = ({
    heading,
    emailPlaceholder,
    passwordPlaceholder,
    repeatPasswordPlaceholder,
    submit
}) => {
    const router = useRouter()

    const [register] = useMutation(RegisterDocument)

    return (
        <NewPasswordForm
            headerClassName={styles.registerHeader}
            containerClassName={styles.form}
            heading={heading}
            passwordPlaceholder={passwordPlaceholder}
            repeatPasswordPlaceholder={repeatPasswordPlaceholder}
            submit={submit}
            firstField={{
                name: 'email',
                placeholder: emailPlaceholder,
                autoFill: 'email'
            }}
            validationSchemaExtension={Yup.object().shape({
                email: Yup.string()
                    .required('Обязательное поле.')
                    .email('Некорректный email.')
            })}
            onSubmit={async ({ email, password }, helpers) => {
                const response = await register({
                    variables: { email, password }
                })

                helpers.setSubmitting(false)

                if (response.errors) {
                    helpers.setErrors(extractValidationErrors(response.errors))
                    return
                }

                await router.reload()
            }}
        />
    )
}

export default RegisterForm
