import { GetStaticProps, NextPage } from 'next/types'
import styles from 'styles/pages/Auth.module.sass'
import Headline from '../components/Headline'
import AvatarList from '../components/AvatarList'
import classNames from 'classnames'
import Markdown from '../components/Markdown'
import LoginForm from '../components/Auth/LoginForm'
import RegisterForm from '../components/Auth/RegisterForm'
import {
    AuthPageStaticDataDocument,
    AuthPageStaticDataQuery
} from '../graphql/generated'
import apolloClient from 'graphql/helpers/apollo'
import React, { ComponentProps } from 'react'
import Layout from '../components/Layout'

import logger from './../util/log'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/client'
import client from 'graphql/helpers/apollo'

export const getStaticProps: GetStaticProps<AuthProps> = async () => {
    await logger('index page')
    const response = (
        await apolloClient.query<AuthPageStaticDataQuery>({
            query: AuthPageStaticDataDocument,
            fetchPolicy: 'network-only'
        })
    ).data?.static_page_data_by_pk as AuthProps

    if (!response) {
        throw new Error('No static data found')
    }

    const {
        headline,
        forgotPassword,
        legalNotice,
        loginFormModel,
        registerFormModel,
        subHeadline
    } = response

    return {
        props: {
            headline,
            subHeadline,
            loginFormModel,
            forgotPassword,
            registerFormModel,
            legalNotice
        }
    }
}

type AuthProps = {
    headline: string
    forgotPassword: string
    legalNotice: string
    loginFormModel: ComponentProps<typeof LoginForm>
    registerFormModel: ComponentProps<typeof RegisterForm>
    subHeadline: string
}

const Auth: NextPage<AuthProps> = ({
    headline,
    subHeadline,
    loginFormModel,
    forgotPassword,
    registerFormModel,
    legalNotice
}) => {
    const SubHeadline = () => (
        <p className={styles.p}>
            <Markdown>{subHeadline}</Markdown>
        </p>
    )

    const avatars = [
        { src: '/images/avatars/Avatar1.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar2.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar3.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar4.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar5.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar6.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar7.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar8.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar9.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar10.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar11.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar12.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar13.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar14.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar15.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar16.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar17.png', alt: 'Avatar' },
        { src: '/images/avatars/Avatar18.png', alt: 'Avatar' }
    ]

    const HeadlineColumn = () => (
        <div className={classNames(styles.column, styles.headlineColumn)}>
            <Headline>{headline}</Headline>
            <div className={styles.subHeadline}>
                <SubHeadline />
                <AvatarList small wrap avatars={avatars} noAvatarsBackground />
            </div>
        </div>
    )

    const ForgotPassword = () => (
        <div className={styles.forgotPassword}></div>
        // <Markdown className={styles.forgotPassword}>
        //     {forgotPassword}
        // </Markdown>
    )

    const LegalNotice = () => (
        <small className={styles.policies}>
            <Markdown linkTarget="_blank">{legalNotice}</Markdown>
        </small>
    )

    return (
        <ApolloProvider client={client}>
            <Layout
                headerProps={{
                    logoHref: '/welcome',
                    title: 'Симбиоз'
                }}
            >
                <main className={styles.div}>
                    <Head>
                        <title>
                            СИМБИОЗ: союз представителей креативных индустрий
                        </title>
                        <meta
                            name="description"
                            content="Сообщество специалистов креативных направлений. Создаем коллаборации, заключаем партнерства, ищем сотрудников в проекты, развиваем коммуникацию."
                        />
                    </Head>
                    <div className={styles.innerContainer}>
                        <HeadlineColumn />
                        <div className={styles.column}>
                            <LoginForm {...loginFormModel} />
                            <ForgotPassword />
                            <RegisterForm {...registerFormModel} />
                            <LegalNotice />
                        </div>
                    </div>
                </main>
            </Layout>
        </ApolloProvider>
    )
}

export default Auth
