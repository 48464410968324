import { ComponentProps, FC, useState } from 'react'
import * as Yup from 'yup'
import MultiFieldTextForm from '../DataEntry/MultiFieldTextForm'
import styles from '../../styles/pages/Auth.module.sass'
import { FormikHelpers } from 'formik'

type RegisterFormProps = {
    heading: string
    passwordPlaceholder: string
    repeatPasswordPlaceholder: string
    submit: string
    firstField: ComponentProps<typeof MultiFieldTextForm>['fields'][0]
    validationSchemaExtension?: Yup.AnyObjectSchema
    headerClassName?: string
    containerClassName?: string
    onSubmit?: (values: any, helpers: FormikHelpers<any>) => void
}

const NewPasswordForm: FC<RegisterFormProps> = ({
    heading,
    passwordPlaceholder,
    repeatPasswordPlaceholder,
    submit,
    headerClassName,
    containerClassName,
    onSubmit,
    firstField,
    validationSchemaExtension
}) => {
    let registerSchema = Yup.object().shape({
        password: Yup.string()
            .required('Обязательное поле.')
            .min(8, 'Пароль не должен быть короче 8 символов.'),
        repeatPassword: Yup.string()
            .required('Обязательное поле')
            .oneOf([Yup.ref('password')], 'Пароли должны совпадать.')
    })

    if (validationSchemaExtension)
        registerSchema = registerSchema.concat(validationSchemaExtension)

    const [passwordsMatch, setPasswordsMatch] = useState(false)
    const [repeatPasswordTouched, setRepeatPasswordTouched] = useState(false)

    const computeIcons = () =>
        !repeatPasswordTouched
            ? undefined
            : passwordsMatch
            ? ([
                  {
                      name: 'check-circle',
                      className: styles.green
                  }
              ] as const)
            : ([
                  {
                      name: 'uncheck-circle',
                      className: styles.red
                  }
              ] as const)

    return (
        <MultiFieldTextForm
            headerClassName={headerClassName}
            containerClassName={containerClassName}
            title={heading}
            submitText={submit}
            buttonType="secondary"
            fields={
                [
                    firstField,
                    {
                        name: 'password',
                        placeholder: passwordPlaceholder,
                        autoFill: 'new-password',
                        password: true,
                        infoIcons: computeIcons()
                    },
                    {
                        name: 'repeatPassword',
                        placeholder: repeatPasswordPlaceholder,
                        autoFill: 'new-password',
                        password: true,
                        validateOnFocus: true,
                        validationErrorPredicate: (touched, values) => {
                            touched = Boolean(touched)

                            setRepeatPasswordTouched(touched)
                            setPasswordsMatch(
                                values.password === values.repeatPassword
                            )

                            return touched
                        },
                        infoIcons: !repeatPasswordTouched
                            ? undefined
                            : computeIcons()
                    }
                ] as const
            }
            validationSchema={registerSchema}
            onSubmit={onSubmit}
        />
    )
}

export default NewPasswordForm
