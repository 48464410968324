import { FC, MutableRefObject } from 'react'
import { StaticImageData } from 'next/future/image'
import classNames from 'classnames'
import styles from 'styles/components/AvatarList.module.sass'
import Avatar from './Personal/Avatar'

type AvatarProps = {
    src?: string | StaticImageData
    alt: string
    href?: string
}

type AvatarListProps = {
    avatars: AvatarProps[]
    wrap?: boolean
    small?: boolean
    refOffset?: number
    avatarAtOffsetRef?: MutableRefObject<HTMLAnchorElement | null>
    noAvatarsBackground?: boolean
}

const AvatarList: FC<AvatarListProps> = ({
    avatars,
    wrap = false,
    small = false,
    refOffset = 0,
    avatarAtOffsetRef,
    noAvatarsBackground
}) => {
    return (
        <div className={classNames(styles.div, { [styles.wrap ?? '']: wrap })}>
            {avatars.map((avatar, index, array) => (
                <Avatar
                    noBackground={noAvatarsBackground}
                    avatarContainerRef={
                        index === array.length - 1 - refOffset
                            ? avatarAtOffsetRef
                            : undefined
                    }
                    key={index}
                    src={avatar.src}
                    alt={avatar.alt}
                    href={avatar.href}
                    size={small ? 'extra-small' : 'small'}
                ></Avatar>
            ))}
        </div>
    )
}

AvatarList.displayName = 'AvatarList'
export default AvatarList
