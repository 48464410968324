import { FC } from 'react'
import Button from '../Button'
import styles from 'styles/components/Welcome/LoginCta.module.sass'

type LoginCtaProps = {
    text: string
    buttonText: string
    href?: string
}

const LoginCta: FC<LoginCtaProps> = ({ text, buttonText, href = '' }) => (
    <div className={styles.container}>
        <span className={styles.text}>{text}</span>
        <Button
            className={styles.button}
            small
            href={href}
            restProps={{ prefetch: false }}
        >
            {buttonText}
        </Button>
    </div>
)

export default LoginCta
