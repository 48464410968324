import styles from 'styles/components/DataEntry/MultiFieldTextForm.module.sass'
import TextEntry, { AutocompleteProvider } from './TextEntry'
import Button from '../Button'
import { Form, Formik, FormikHelpers, FormikValues } from 'formik'
import * as Yup from 'yup'
import { ComponentProps } from 'react'
import classNames from 'classnames'
import Markdown from '../Markdown'
import type TextInput from './TextEntry/TextInput'

type Field = {
    readonly name: string
    readonly initialValue?: string
    placeholder?: string
    disabled?: boolean

    validateOnFocus?: boolean
    validationErrorPredicate?: (
        touched: boolean | undefined,
        values: FormikValues
    ) => boolean
}

type SingularField = Field & {
    tagsInput?: false
    textArea?: false
    password?: boolean
    autoFill?: ComponentProps<typeof TextInput>['autoFill']
    infoIcons?: Readonly<ComponentProps<typeof TextEntry>['infoIcons']>
    autocompleteProvider?: AutocompleteProvider
    type?: ComponentProps<typeof TextInput>['type']
    onBlur?: (value: string, setValue: (value: string) => void) => void
}

type AreaField = Field & {
    tagsInput?: false
    textArea: true
}

type TagsField = Field & {
    textArea?: false
    tagsInput: true
}

type FieldsCollection = ReadonlyArray<SingularField | AreaField | TagsField>

type FormProps<
    T extends FieldsCollection,
    V = FieldsCollectionToFieldSet<T[number]>
> = {
    title: string
    submitText: string

    buttonBounded?: boolean
    buttonType?: ComponentProps<typeof Button>['type']
    additionalButtons?: {
        text: string
        type?: ComponentProps<typeof Button>['type']
        disabled?: boolean
        bounded?: boolean
        onClick?: () => void
    }[]

    headerClassName?: string

    containerClassName?: string
    fields: T
    validationSchema?: Yup.AnyObjectSchema
    subtitle?: string
    onSubmit?: (values: V, helpers: FormikHelpers<V>) => void
}

type FieldsCollectionToFieldSet<T extends Field> = Record<T['name'], string>

const fieldsReducer = <T extends FieldsCollection>(
    fieldSet: FieldsCollectionToFieldSet<T[number]>,
    field: T[number]
) => ({ ...fieldSet, [field.name]: field.initialValue ?? '' })

const MultiFieldTextForm = <T extends FieldsCollection>({
    title,
    buttonBounded,
    buttonType,
    headerClassName,
    containerClassName,
    fields,
    validationSchema,
    subtitle,
    submitText,
    additionalButtons,
    onSubmit = () => {}
}: FormProps<T>) => {
    return (
        <Formik
            initialValues={fields.reduce(
                fieldsReducer,
                {} as FieldsCollectionToFieldSet<T[number]>
            )}
            validationSchema={validationSchema}
            onSubmit={onSubmit as any}
        >
            {({
                isSubmitting,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setFieldValue
            }) => (
                <Form
                    className={
                        containerClassName ? containerClassName : styles.form
                    }
                >
                    <fieldset className={styles.fieldset}>
                        <Markdown
                            className={
                                headerClassName
                                    ? headerClassName
                                    : styles.h1Default
                            }
                        >
                            {title}
                        </Markdown>
                        {subtitle && (
                            <Markdown className={styles.h2}>
                                {subtitle}
                            </Markdown>
                        )}
                        {fields.map(field => {
                            const name = field.name as T[number]['name']

                            if (
                                !field.textArea &&
                                !field.tagsInput &&
                                field.autocompleteProvider
                            ) {
                                const previousOnSelect =
                                    field.autocompleteProvider.onSelect

                                field.autocompleteProvider.onSelect = value => {
                                    previousOnSelect?.(value)
                                    setFieldValue(name, value, true)
                                }
                            }

                            return (
                                <TextEntry
                                    key={field.name}
                                    password={
                                        field.textArea || field.tagsInput
                                            ? undefined
                                            : field.password
                                    }
                                    disabled={field.disabled}
                                    tagsInput={field.tagsInput as any}
                                    textArea={field.textArea}
                                    autocompleteProvider={
                                        field.textArea || field.tagsInput
                                            ? undefined
                                            : field.autocompleteProvider
                                    }
                                    name={field.name}
                                    type={
                                        field.textArea || field.tagsInput
                                            ? undefined
                                            : field.type
                                    }
                                    invalidEntryMessage={
                                        (errors[name] &&
                                        (field.validationErrorPredicate
                                            ? field.validationErrorPredicate(
                                                  touched[name] as any,
                                                  values
                                              )
                                            : touched[name])
                                            ? errors[name]
                                            : undefined) as string | undefined
                                    }
                                    value={values[name]}
                                    onChange={value =>
                                        setFieldValue(name, value, true)
                                    }
                                    onChangeRaw={handleChange}
                                    onBlur={e => {
                                        if (!field.validateOnFocus)
                                            handleBlur(e)

                                        if (
                                            !field.textArea &&
                                            !field.tagsInput &&
                                            field.onBlur
                                        ) {
                                            field.onBlur(
                                                values[name],
                                                newValue =>
                                                    setFieldValue(
                                                        name,
                                                        newValue,
                                                        true
                                                    )
                                            )
                                        }
                                    }}
                                    onFocus={
                                        field.validateOnFocus
                                            ? handleBlur
                                            : undefined
                                    }
                                    infoIcons={
                                        field.textArea || field.tagsInput
                                            ? undefined
                                            : (field.infoIcons as any)
                                    }
                                    autoFill={
                                        field.textArea || field.tagsInput
                                            ? undefined
                                            : field.autoFill
                                    }
                                >
                                    {field.placeholder || ''}
                                </TextEntry>
                            )
                        })}
                        <Button
                            unstyledDisabled={isSubmitting}
                            bounded={buttonBounded}
                            className={classNames({
                                [styles.buttonBounded ?? '']: buttonBounded
                            })}
                            type={buttonType ? buttonType : 'primary'}
                            small
                            submit
                        >
                            {submitText}
                        </Button>
                        {additionalButtons &&
                            additionalButtons.map(
                                ({
                                    text,
                                    type,
                                    disabled = false,
                                    bounded,
                                    onClick
                                }) => (
                                    <Button
                                        key={text}
                                        unstyledDisabled={disabled}
                                        bounded={bounded}
                                        className={classNames({
                                            [styles.buttonBounded ?? '']:
                                                bounded
                                        })}
                                        type={type ? type : 'primary'}
                                        small
                                        onClick={onClick}
                                    >
                                        {text}
                                    </Button>
                                )
                            )}
                    </fieldset>
                </Form>
            )}
        </Formik>
    )
}

export default MultiFieldTextForm
